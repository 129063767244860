import PropTypes from 'prop-types'
import React from 'react'
import {Link} from "gatsby";

const Pagination = ({pageContext, pagePath, lang}) => {
  if (pageContext !== undefined && pageContext.numberOfPages > 1) {
    let topPath = ""

    if(lang === "ja"){
      topPath = ""
    }else if(lang === "en"){
      topPath = "/en"
    }

    let prevPageLink = pageContext.previousPagePath !== "" ? (
        <li className="pagination__item--arrow"><Link to={pageContext.previousPagePath}>&#x21BC;</Link></li>) : ""
    let nextPageLink = pageContext.nextPagePath !== "" ? (
        <li className="pagination__item--arrow"><Link to={pageContext.nextPagePath}>&#x21C0;</Link></li>) : ""
    let pageNumbers = []
    for (let i = 1; i <= pageContext.numberOfPages; i++) {
      let postfix = i === pageContext.humanPageNumber ? "--current" : ""
      let link = i === 1 ? "" : i
      pageNumbers.push(
          <li className={`pagination__item${postfix}`}>
            <Link to={topPath + pagePath + "/" + link}>{i}</Link>
          </li>
      )
    }
    return (
        <section className="pagination">
          <ul className="pagination__wrap">
            {prevPageLink}
            <li className="pagination__item">
              <ul className="pagination__wrap--child">
                {pageNumbers}
              </ul>
            </li>
            {nextPageLink}
          </ul>
        </section>
    )
  }
  else {
    return ""
  }
}

export default Pagination