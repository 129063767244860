import React from 'react'
import {Link} from 'gatsby'
import Image from "./image";
import Img from "gatsby-image";
import Pagination from "./pagination";

const ArticleList = ({blogs, type = 'enumeration', lang, heading, pages, pageContext, pagePath}) => {
  let headingText = heading
  if (heading === undefined) {
    headingText = lang === "en" ? blogs[0].node.category.name_en : blogs[0].node.category.name
  }
  let linkPrefix = lang === "en" ? "/en" : ""

  // Display link button to All Posts in except All posts page.
  let seeAll = lang === "en" ? (<Link to={`${linkPrefix}/all`} className="article-list__link-all">All Posts</Link>) : (<Link to={`${linkPrefix}/all`} className="article-list__link-all">全ての記事</Link>)

  // Display page numbers
  let dispPages = pages !==  undefined ? (<div className="article-list__pages">{pages}</div>) : ""
  if(heading === "全ての記事" || heading === "All Posts") seeAll = null

  return (
    <section className="article-list">
      <div className="article-list__header">
        {dispPages}
        <h2 className="article-list__heading">{headingText}</h2>
        {seeAll}
      </div>
      <ul className={`article-list__list--${type}`}>
        {blogs.map(blog => {
              let title = lang === "en" ? blog.node.title_en : blog.node.title
              let categoryName = lang === "en" ? blog.node.category.name_en : blog.node.category.name
              return (
                  <li key={blog.node.id} className="article-list__list-item list-item">
                    <div className="list-item__info-box">
                      <h2 className="list-item__title">
                        <Link to={`${linkPrefix}/${blog.node.slug}`}>{title}</Link>
                      </h2>
                      <p className="list-item__date">{blog.node.publishDate}</p>
                      <Link to={`${linkPrefix}/category/${blog.node.category.slug}`} className="list-item__category">{categoryName}</Link>
                    </div>
                    <div className="list-item__thumbnail">
                      {(image => {
                        return image == null ? (<Image/>) : (
                            <Img fixed={image.childImageSharp.fixed}/>)
                      })(blog.node.featuredImage)}
                    </div>
                  </li>
              )
            }
        )}
      </ul>
      <Pagination pageContext={pageContext} pagePath={pagePath} lang={lang}/>
    </section>
)}

export default ArticleList
