import React from 'react'
import {Link, graphql} from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'
import ArticleList from "../components/article-list";

import Witticism from '../components/witticism'

const IndexPage = (response) => {
  return (
      <Layout lang="ja" pagePath="">
        <SEO title="ホーム" keywords={[`Pikon log`, `Pikon Inc.`, `技術ブログ`]} lang="ja" description="株式会社ピコン制作所のメンバーによる技術メモ"/>
        <Witticism lang="ja"/>
        <ArticleList blogs={response.data.allStrapiBlog.edges}
                     type="enumeration"
                     lang="ja"
                     heading="新着記事"
        />
      </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`  
  query IndexQuery {
    allStrapiBlog(
      filter:{
        status:{
          eq:true
        }
      },
      sort:{
        fields: [publishDate, id]
  	    order: [DESC, DESC]
      }
    ) {
      edges {
        node {
          id
          title
          publishDate(formatString: "YYYY.MM.DD")
          strapiId
          slug
          isTranslated
          category {
            id
            name
            name_en
            slug
          }
          featuredImage {
            id
            childImageSharp{
              fixed(width: 160,height: 120) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`