import {graphql, Link, StaticQuery} from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

// Load Logo
import logo from '../images/logo_pikonlog.svg'

const Header = (props) => (
    <StaticQuery
        query={graphql`
          query HeaderQuery {
            allStrapiCategory {
              edges {
                node {
                  id
                  name
                  slug
                  name_en
                }
              }
            }
          }
        `}
        render={data => {

          let activeJa = "--active"
          let activeEn = ""
          let topPath = "/"

          if(props.lang === "ja"){
            activeJa = "--active"
            activeEn = ""
            topPath = "/"
          }else if(props.lang === "en"){
            activeJa = ""
            activeEn = "--active"
            topPath = "/en/"
          }

          return (
            <header className="header">
              <div className="header__logo">
                <Link to={topPath}>
                  <img src={logo} alt="PIKON LOG" width="160" height="24"/>
                </Link>
              </div>
              <nav className="header__nav category-nav">
                <ul className="category-nav__list">
                  {data.allStrapiCategory.edges.map(document => {
                    let name = props.lang === 'ja' ? document.node.name : document.node.name_en
                    let active = document.node.slug === props.category ? "active" : ""
                    let linkPrefix = props.lang === 'ja' ? "" : "/en"
                    return (
                            <li key={document.node.id} className="category-nav__list-item">
                              <Link to={`${linkPrefix}/category/${document.node.slug}`} className={active}>
                                {name}
                              </Link>
                            </li>
                        )
                      }
                  )}
                </ul>
              </nav>
              <div className="header__change-lang change-lang">
                <Link className={`change-lang__link${activeJa}`} to={"/"+props.pagePath}>JA</Link>
                <span>/</span>
                <Link className={`change-lang__link${activeEn}`} to={"/en/"+props.pagePath}>EN</Link>
              </div>
            </header>
        )}
        }
    />
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header