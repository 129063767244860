import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

// Load Corporate Logo
import pikonLogo from '../images/logo_pikon.svg'

import Header from './header'
import '../scss/style.css'
import logo from "../images/logo_pikonlog.svg";

const Layout = ({ children, lang, pagePath, category}) => (
    <div className={`lang--${lang}`}>
      <Header siteTitle={'Pikon log'} lang={lang} pagePath={pagePath} category={category}/>
      <main role="main">{children}</main>
      <footer className="footer">
        <img src={pikonLogo} alt="PIKON LOG" width="112" height="27"/>
        <p className="footer__copyright">{new Date().getFullYear()} © Pikon Inc. All RIght Reserved.</p>
      </footer>
    </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
