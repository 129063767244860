export const imagePath = "http://log.pikon.jp:1337"
export const deployPath = "http://log.pikon.jp"
export const sentences = [
  {
    name:"アラン・ケイ",
    value:"“未来を予測する最良の方法は、それを発明することである”"
  },
  {
    name:"スティーブ・ジョブズ",
    value:"“重要なことに集中する唯一の方法は「ノー」と言うことだ”"
  },
]

export const sentencesEn = [
  {
    name:"Alan Kay",
    value:"“The best way to predict the future is to invent it.”"
  },
  {
    name:"Steve Jobs",
    value:"“It’s only by saying no that you can concentrate on the things that are really important.”"
  },
]

export const getExerpt = (text) => {
  return text.substr(0,128)
}