import React from 'react'
import {sentences, sentencesEn} from '../environment'

class Witticism extends React.Component {
  constructor(){
    super()
    this.sentences = sentences
    this.sentencesEn = sentencesEn
  }

  componentWillMount() {
    if(this.props.lang === "en") {
      this.sentence = this.sentencesEn[this.getRandomInt(this.sentencesEn.length)]
    }else{
      this.sentence = this.sentences[this.getRandomInt(this.sentences.length)]
    }
  }

  getRandomInt(max){
    return Math.floor(Math.random() * Math.floor(max))
  }

  render(){
    return(
        <section className="witticism">
          <h2 className="witticism__title">
            {this.sentence.value}<br/>
            <span className="witticism__title--small">{this.sentence.name}</span>
          </h2>
        </section>
    )
  }
}

export default Witticism